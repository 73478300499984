@tailwind base;
@tailwind components;
@tailwind utilities;

body, .modalBg {
  background-color: #1d191f;
}

.whiteColor {
  color: white;
} 

.center {
  justify-content: center;
  display: flex;
  align-items: center;
}

.pb-6 { 
  margin-top: 100px !important; 
  margin-bottom: 100px !important;
}
.gameBlock { 
  margin-top: 55px !important;
}
.btnEnter { 
  margin-top: 30px !important;
}
.btnReset { 
  margin-bottom: 30px !important;
}

@media (max-height: 700px) {
  .pb-6 {
    margin-top: 0px !important; 
    margin-bottom: 0px !important;
    padding-bottom: 5px !important;
  }
  .titleBlock {
    margin-bottom: 5px !important;
  }
  .gameBlock { 
    margin-top: 33px !important;
  }
  .btnEnter { 
    margin-top: 5px !important;
  }
  .btnReset { 
    margin-bottom: 5px !important;
  }
  .cellBlock {
      width: 2.7em !important;
      height: 2.7em !important;
  }
}
@media (max-height: 800px) and (min-height: 700px) {
  .pb-6 {
    margin-top: 5px !important; 
    margin-bottom: 5px !important;
    padding-bottom: 5px !important;
  }
  .titleBlock {
    margin-bottom: 10px !important;
  }
  .gameBlock { 
    margin-top: 38px !important;
  }
  .btnEnter { 
    margin-top: 10px !important;
  }
  .btnReset { 
    margin-bottom: 10px !important;
  }
}
@media (max-height: 900px) and (min-height: 800px) {
  .pb-6 {
    margin-top: 30px !important; 
    margin-bottom: 30px !important;
    padding-bottom: 5px !important;
  }
}
@media (max-height: 1024px) and (min-height: 900px) {
  .pb-6 {
    margin-top: 60px !important; 
    margin-bottom: 60px !important;
  }
}